import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'mobile',
			adsenseConfig: {
				slot1: '',
				slot2: '',
				slot3: '',
				client: '',
				scriptUrl: '',
				cid: 97,
				ads: 'success',
			},
			firebaseConfig: {
				apiKey: 'AIzaSyCOPcbn2sfxXiBYJQ2drJ6dkCG8FW3K4eg',
				authDomain: 'enetwork-71ebd.firebaseapp.com',
				projectId: 'enetwork-71ebd',
				storageBucket: 'enetwork-71ebd.appspot.com',
				messagingSenderId: '178708903326',
				appId: '1:178708903326:web:91deef4e9bd2b80d6cd28f',
				measurementId: 'G-DEFAULT-MID',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setAdsense(state, adsenseConfig) {
				state.adsenseConfig = adsenseConfig
			},
			setFirebase(state, firebaseConfig) {
				state.firebaseConfig = firebaseConfig
			},
		},
	})
}
