/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-06-26 11:17:56
 */

// import { api } from './service.js'
import { service } from './service.js'

const server = {
	eget(req) {
		return service.get('/express/eget', req)
	},
	epost(req) {
		return service.post('/express/epost', req)
	},
	erate(req) {
		return service.post('/express/rate', req)
	},

	getNews(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/tc/a/detail`, req)
	},
	getList(req) {
		return service.post(`${process.env.VUE_APP_BASE_API}/tc/a/summary`, req)
	},
	//https://yapi.adsconflux.xyz/project/439/interface/api/1569
}

function download(apk, name) {
	// // 创建隐藏的可下载链接
	if (apk.includes('www.gbwhatsapp.download')) {
		apk = apk.replace('www.gbwhatsapp.download', 'cdn.gbwhatsapp.download')
	}

	let eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)

	// eleLink.setAttribute('download', name)
	eleLink.style.display = 'none'
	// eleLink.setAttribute('target', '_blank')
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		let reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		let r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	return fmt
}

function formatTimestamp(timestamp) {
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	let date = new Date(timestamp)
	let month = months[date.getMonth()]
	let day = date.getDate()
	let year = date.getFullYear()
	return month + ' ' + day + ',' + year
}

const firebaseConfig = {
	apiKey: 'AIzaSyB2--Z5taGYyPsn3bqhSzM9JQHGkIb4RB8',
	authDomain: 'techcrunchabc-click.firebaseapp.com',
	projectId: 'techcrunchabc-click',
	storageBucket: 'techcrunchabc-click.appspot.com',
	messagingSenderId: '1080475416132',
	appId: '1:1080475416132:web:9e04a6fd65a4c3bec42e11',
	measurementId: 'G-BVWQ96H13C',
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatDate,
	server,
	download,
	formatTimestamp,
	firebaseConfig,
}
